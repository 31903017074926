import { Component, OnInit } from '@angular/core';
import { Title } from "@angular/platform-browser";

import { EventService } from "../../../core/services/event.service";
import { NavBarOptionModel } from "../../../core/models/navBarOption.model";
import { DialogService } from "../../../core/services/dialog.service";
import { Assistant } from "../../../core/models/assistant.model";
import { AssistantService } from "../../../core/services/assistant.service";
import { AlertService } from "../../../core/services/alert.service";
import { AssistantLanguage } from "../../../core/models/language.model";

@Component({
    selector: 'app-assistant',
    templateUrl: './assistant.component.html',
    styleUrl: './assistant.component.scss',
})
export class AssistantComponent implements OnInit {
    plusImage: string = 'assets/icons/plus.svg';
    cursorImage: string = 'assets/icons/cursor.svg';
    checkImage: string = 'assets/icons/checked.svg';
    hoverImage: string = 'assets/icons/hoverAssistant.svg';

    selectAssistantLabel: string = 'Select Assistant';
    assistantKnowledgeLabel: string = 'Assistant Knowledge';
    assistantAnalyticsLabel: string = 'Analytics';
    selectSection: string = 'Start with Assistant selection, then manage its knowledge.'
    knowledgeSection: string = 'Welcome to Assistant Knowledge board! Select desired knowledge for Assistant.'
    descriptionSection: string[] = [
        `Welcome to Assistant dashboard!`,
        `Create new Assistant, assign knowledge, add actions and review analytics to
         enhance your assistant's performance.`];

    assistants: Assistant[] = [];
    activeTab: string = 'knowledge'; // Default tab
    dropdownOpen = false;
    selectedAssistant: Assistant | null = null;
    selectedAssistantIndex: number | null = null;

    constructor (
        private title: Title,
        private event: EventService,
        private dialog: DialogService,
        private assistant: AssistantService,
        private alert: AlertService) {
        this.title.setTitle('Assistants');
    }

    async ngOnInit() {
        this.event.setCurrentTab('Assistants');

        const option: NavBarOptionModel = { title: 'Assistants', search: 'Type here to search assistants...', create: 'Create new assistant' };
        this.event.setContentFromNavbarOption(option);

        await this.loadAssistants();
    }

    async loadAssistants() {
        this.alert.showLoading();
        const response = await this.assistant.getAssistants('');
        this.assistants = response.body;
        console.log('Available Assistants:', this.assistants)
    }

    async loadAssistantInfo(id: string) {
        this.alert.showLoading();
        const response = await this.assistant.getAssistant(id);
        return response.body;
    }

    async selectAssistant(index: number, assistant: Assistant) {
        this.dropdownOpen = false;
        this.selectedAssistant = assistant;
        this.selectedAssistantIndex = index;

        if (this.selectedAssistant && this.selectedAssistant.id) {
            const selectedAssistantInfo = await this.loadAssistantInfo(this.selectedAssistant.id);
            this.assistant.setSelectedAssistant(selectedAssistantInfo);

            console.log('Selected Assistant:', this.selectedAssistant);
        }
    }

    editAssistant() {
        this.dialog.openEditAssistantDialog();
    }

    add() {
        this.dialog.openCreateAssistantDialog();
    }

    addKnowledge() {
        this.dialog.openAddKnowledgeToAssistantDialog();
    }

    isAssistantEmpty(): boolean {
        return !(this.assistants && this.assistants.length > 0);
    }

    selectTab(tab: string) {
        this.activeTab = tab;
    }

    toggleDropdown() {
        this.dropdownOpen = !this.dropdownOpen;
    }

    getSelectedAssistantLabel(): string {
        if (this.selectedAssistant !== null) {
            return this.selectedAssistant.name;
        } else {
            return this.selectAssistantLabel;
        }
    }

    isSelectAssistant(): boolean {
        return this.selectedAssistant !== null;
    }
}
