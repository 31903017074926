import { Component, OnInit } from '@angular/core';
import { Title } from "@angular/platform-browser";

import { ScreenDisplayFormat } from "../../../core/models/screenDisplay.model";
import { EventService } from "../../../core/services/event.service";
import { NavBarOptionModel } from "../../../core/models/navBarOption.model";
import { RouterService } from "../../../core/services/router.service";

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrl: './landing.component.scss'
})
export class LandingComponent implements OnInit {
    screenAssistant: ScreenDisplayFormat = {
        title: 'Assistants',
        description:
                `Digital assistant offers 24/7 support, personalized recommendations, and streamlined bookings. It reduces
                administrative workload and provides real-time data and analytics for informed decision-making.`
    };
    screenDataInsight: ScreenDisplayFormat = {
        title: 'Data Insight',
        description:
                `The Data Insights panel lets you track real-time queries, customer feedback, and agent usage, offering
                a clear view of user interactions. It helps you monitor performance metrics and make data-driven improvements.`
    }

    constructor (private title: Title, private event: EventService, private router: RouterService) {
        this.title.setTitle('Landing');
    }

    ngOnInit(): void {
        const option: NavBarOptionModel = { title: '', search: '', create: '' };
        this.event.setContentFromNavbarOption(option)
    }

    openAssistant() {
        this.router.goToAssistant();
    }

    openDataInsight() {
        // TODO change this to go to Elastic
        alert('Go to Elastic');
    }
}
