<div class="box-search">
    <div class="section-actions">
        <div class="search-container">
            <img [src]="loupeImage" alt="Loupe icon">
            <input type="text" name="search-placeholder" id="id-search-project"
                   [placeholder]="searchPlaceholder" [(ngModel)]="searchValue"
                   (keyup.enter)="search()"/>
        </div>
        <button id="id-create-option" type="button" class="create-option-button" (click)="create()">
            <img [src]="plusImage" alt="Plus icon">
            <span>{{ createButton }}</span>
        </button>
    </div>
</div>
