import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";

@Component({
    selector: 'app-example',
    templateUrl: './example.component.html',
    styleUrl: './example.component.scss'
})
export class ExampleComponent {

    constructor (private title: Title) {
        this.title.setTitle('Example');
    }

}
