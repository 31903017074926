<mat-dialog-content>
    <div class="dialog-container">
        <div class="dialog-header">
            <img [src]="sucessImage" alt="Sucess icon">
            <h2>{{ dialogTitle }}</h2>
            <p>{{ dialogDescription }}</p>
            <button id="id-close-dialog" type="button" class="close-button" (click)="closeDialog()">
                <app-close></app-close>
            </button>
        </div>
        <div class="dialog-footer">
            <button id="id-add-assistant-knowledge" type="button" class="assistant-knowledge" (click)="addKnowledge()">
                <img [src]="plusImage" alt="Plus icon">
                <span>{{ knowledgeButton }}</span>
            </button>
            <div>
                <button *ngIf="!isGoToAssistant" id="id-go-to-assistant" type="button" class="go-to-assistant" (click)="goToAssistant()">
                    <app-go-to-assistant></app-go-to-assistant>
                    <span>{{ assistantButton }}</span>
                </button>
                <div *ngIf="isGoToAssistant" class="assistant-design-container">
                    <div class="design-container" *ngFor="let design of designs; let i = index">
                        <label>{{ design.label }}</label>
                        <div class="design-link-container">
                            <div class="copy-link-container">
                                <input type="text" [id]="'id-design' + (i + 1) + '-link'" [name]="'design' + (i + 1) + '-link'" [placeholder]="design.link" [value]="design.link" readonly/>
                                <button [id]="'id-copy-design' + (i + 1) + '-link'" type="button" class="copy-button" (click)="copyTextMessage(design.link, i)">
                                    <app-copy *ngIf="!isLinkCopied[i]"></app-copy>
                                    <app-correct *ngIf="isLinkCopied[i]"></app-correct>
                                </button>
                            </div>
                            <button [id]="'go-to-link-design' + (i + 1)" type="button" class="go-to-link-button" (click)="goToLink(design.link)">
                                {{ goToLinkButton }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>
