<div class="box-account">
    <div class="account-wrapper">
        <p class="user-name">{{ userName }}</p>
        <button id="id-account" class="account-container" (click)="toggleDropdown()">
            <div *ngIf="isVisibleMenu" class="account-menu">
                <button id="id-account-settings" class="menu-item" (click)="settings()">
                    <img [src]="settingsImage" alt="Settings icon">
                    <p>{{ settingsLabel }}</p>
                </button>
                <button id="id-account-logout" class="menu-item" (click)="logout()">
                    <img [src]="logoutImage" alt="Logout icon">
                    <p>{{ logoutLabel }}</p>
                </button>
            </div>
            <img [src]="accountImage" alt="Account icon">
        </button>
    </div>
</div>
