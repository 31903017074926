import { Component } from '@angular/core';

import { DialogService } from "../../../core/services/dialog.service";

@Component({
    selector: 'app-create-action-modal',
    templateUrl: './create-action-modal.component.html',
    styleUrl: './create-action-modal.component.scss'
})
export class CreateActionModalComponent {
    dialogTitle = 'Add action';

    constructor(private dialog: DialogService) { }

    closeDialog() {
        this.resetFieldValues();
        this.dialog.close();
    }

    resetFieldValues() {

    }
}
