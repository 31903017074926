import { Component, ElementRef, HostListener, OnInit } from '@angular/core';

import { ConfigService } from "../../../core/services/config.service";
import { AuthService } from "../../../core/services/auth.service";

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrl: './account.component.scss'
})
export class AccountComponent implements OnInit{
    logoutImage: string = 'assets/icons/logout.svg';
    settingsImage: string = 'assets/icons/settings.svg';
    logoutLabel: string = 'Logout';
    settingsLabel: string = 'Settings';

    accountImage: string = 'assets/icons/account.svg';

    isVisibleMenu: boolean = false;
    userName: string = '';


    // Close dropdown when clicking outside
    @HostListener('document:click', ['$event'])
    clickoutAccountMenu(event: Event) {
        if (!this.elementRef.nativeElement.contains(event.target)) {
            this.isVisibleMenu = false;
        }
    }

    constructor(private elementRef: ElementRef, private config: ConfigService, private auth: AuthService) { }

    ngOnInit(): void {
        this.userName = this.config.getUserName();
    }

    toggleDropdown() {
        this.isVisibleMenu = !this.isVisibleMenu;
    }

    logout() {
        this.auth.logout();
    }

    settings() {

    }
}
