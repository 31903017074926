import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";

import { EventService } from "../../../core/services/event.service";
import { AssistantService } from "../../../core/services/assistant.service";
import { DialogService } from "../../../core/services/dialog.service";
import { KnowledgeService } from "../../../core/services/knowledge.service";
import { ActionService } from "../../../core/services/action.service";
import { AlertService } from "../../../core/services/alert.service";

@Component({
    selector: 'app-search-area',
    templateUrl: './search-area.component.html',
    styleUrl: './search-area.component.scss'
})
export class SearchAreaComponent implements OnInit, OnDestroy {
    plusImage: string = 'assets/icons/plus.svg';
    loupeImage: string = 'assets/icons/loupe.svg';

    searchPlaceholder: string = '';
    createButton: string = '';
    searchValue: string = '';
    tab: string = '';

    navBarOptionSubscription!: Subscription;
    currentTabSubscription!: Subscription;

    constructor(
        private dialog: DialogService,
        private event: EventService,
        private assistant: AssistantService,
        private knowledge: KnowledgeService,
        private action: ActionService,
        private alert: AlertService) {}

    ngOnInit(): void {
        this.navBarOptionSubscription = this.event.getContentFromNavbarOption().subscribe(navBarOption => {
            if (navBarOption) {
                this.searchPlaceholder = navBarOption.search;
                this.createButton = navBarOption.create;
            }
        });

        this.currentTabSubscription = this.event.getCurrentTab().subscribe(tab => {
            this.tab = tab;
        });
    }

    create() {
        // TODO - Try to come up with a better and more flexible way to do this
        if (this.tab === 'Assistants') { this.dialog.openCreateAssistantDialog(); }
        if (this.tab === 'Learning Hub') { this.dialog.openCreateKnowledgeDialog(); }
        if (this.tab === 'Actions') { this.dialog.openCreateActionDialog(); }
    }

    async search() {
        if (!this.searchValue) { return; }

        this.alert.showLoading();
        // TODO - Try to come up with a better and more flexible way to do this
        if (this.tab === 'Assistants') { await this.assistant.getAssistants(this.searchValue); }
        if (this.tab === 'Learning Hub') { await this.knowledge.getKnowledges(this.searchValue); }
        if (this.tab === 'Actions') { await this.action.getActions(this.searchValue); }

        this.searchValue = '';
    }

    ngOnDestroy() {
        if (this.navBarOptionSubscription) { this.navBarOptionSubscription.unsubscribe(); }
        if (this.currentTabSubscription) { this.currentTabSubscription.unsubscribe(); }
    }
}
