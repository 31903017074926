import { Component, OnDestroy, OnInit } from '@angular/core';

import { DialogService } from "../../../core/services/dialog.service";
import { AssistantLanguage, Language } from "../../../core/models/language.model";
import { AlertService } from "../../../core/services/alert.service";
import { AssistantService } from "../../../core/services/assistant.service";
import { Assistant } from "../../../core/models/assistant.model";
import { ConfigService } from "../../../core/services/config.service";

@Component({
    selector: 'app-edit-assistant-modal',
    templateUrl: './edit-assistant-modal.component.html',
    styleUrl: './edit-assistant-modal.component.scss'
})
export class EditAssistantModalComponent implements OnInit, OnDestroy {
    dialogTitle = 'Edit Assistant';
    changesButton = 'Save changes';

    assistantLabels: string[] = ['Assistant name', 'Assistant role', 'Enter assistant name', 'Enter assistant role']
    knowledgeLabels: string[] = ['Knowledge Scope', 'Controlled', 'Utilizes only custom knowledge.', 'Mixed',
        'Combines both general and custom knowledge.'];
    similarityLabel: string = 'Similarity threshold for attachments (Higher percentage means fewer attachments shown)';
    communicationLabels: string[] = ['Communication style', 'Formal', 'Maintains professionalism, following social norms. ',
        'Informal', 'Keeps things casual and relaxed, using everyday language.'];
    behaviorLabels: string[] = ['Behavior', 'Friendly', 'Approaches interactions warmly, making others feel valued.', 'Curious',
        'Shows a strong interest in learning, often asking questions.', 'Creative',
        'Thinks innovatively, finding unique solutions to problems.', 'Humorous',
        'Injects humor into conversations, aiming to lighten the mood.'];
    asnwerLabel: string = 'Answer length (As the length of the answer increases, the response time also increases)';
    voiceLabels: string[] = ['Voices (First one selected is considered default)', 'Select language(s)'];
    shortcutLabels: string[] = ['Shortcuts', 'Type first shortcut', 'Type second shortcut', 'Type third shortcut', 'Type fourth shortcut']

    languages: Language[] = [];
    selectedLanguagesOrder: string[] = [];
    selectedLanguages: string = '';
    assistantId: string | undefined = '';
    assistantName: string = '';
    assistantRole: string = '';
    shortcutFirst: string = '';
    shortcutSecond: string = '';
    shortcutThird: string = '';
    shortcutFourth: string = '';
    knowledgeScope: string = 'controlled';
    similarityThreshold: number = 0;
    answerLengthThreshold: number = 0;
    communicationStyle: string = 'formal';
    behavior: string = 'friendly';

    constructor(private dialog: DialogService, private alert: AlertService, private assistant: AssistantService, private config: ConfigService) { }

    async ngOnInit() {
        this.languages = this.config.getLanguages();

        this.assistant.getSelectedAssistant().subscribe(async assistant => {
            this.loadAssistantValues(assistant);
            console.log('Get Assistant:', assistant)
        });
    }

    loadAssistantValues(assistant: Assistant) {
        this.assistantId = assistant.id;
        this.assistantName = assistant.name;
        this.assistantRole = assistant.role;
        this.knowledgeScope = assistant.knowledgeScope.toLowerCase();
        this.similarityThreshold = assistant.similarityThreshold;
        this.answerLengthThreshold = assistant.answerLength;
        this.communicationStyle = assistant.communicationStyle.toLowerCase();
        this.behavior = assistant.behavior.toLowerCase();
        this.shortcutFirst = assistant.shortcuts.first;
        this.shortcutSecond = assistant.shortcuts.second;
        this.shortcutThird = assistant.shortcuts.third;
        this.shortcutFourth = assistant.shortcuts.fourth;

        // Checking selected languages from assistant
        const selectedAssLangs = assistant.selectedLanguages.map((selectedLang: AssistantLanguage) => selectedLang.name);
        this.languages.forEach(language => {
            if (selectedAssLangs.includes(language.name)) {
                language.selected = true;
                const selectedLang = assistant.selectedLanguages.find((lang: any) => lang.name === language.name);
                language.selectedVoice = selectedLang ? selectedLang.voice : '';
            }
        });

        // Filled selected languages order
        this.languages.forEach(language => {
            if (language.selected) {
                this.addLanguageToOrder(language);
            }
        });

        console.log(this.languages)
    }

    async editAssistant() {
        this.alert.showSaveChanges('Save changes?', 'Existing settings will be replaced.')
        .then(async (result: { isConfirmed: any; }) => {
            if (result.isConfirmed) {
                this.closeDialog();

                if (this.assistantId) {
                    this.alert.showLoading();
                    const assistant = this.createAssistantObject();
                    console.log('Edited assistant:', assistant);
                    await this.assistant.editAssistant(this.assistantId, assistant);
                }
            }
        });
    }

    closeDialog() {
        this.dialog.close();
    }

    // TODO - Try to use this methods on one place, also with create-assistant-modal component
    toggleVoices(language: Language) {
        this.addLanguageToOrder(language);
        this.setFemaleDefaultVoice(language);
    }

    addLanguageToOrder(language: Language) {
        if (language.selected) {
            if (!this.selectedLanguagesOrder.includes(language.name)) {
                this.selectedLanguagesOrder.push(language.name);
            }
        } else {
            this.selectedLanguagesOrder = this.selectedLanguagesOrder.filter(lang => lang !== language.name);
        }

        this.updateSelectedLanguages();
    }

    updateSelectedLanguages() {
        this.selectedLanguages = this.selectedLanguagesOrder.join(', ');
    }

    setFemaleDefaultVoice(language: Language) {
        if (language.selected) {
            language.selectedVoice = language.voices.length > 0 ? language.voices[0].name : '';
        } else {
            language.selectedVoice = '';
        }
    }

    get isFormValid(): boolean {
        return this.assistantName.trim() !== '' &&
            this.assistantRole.trim() !== '' &&
            this.selectedLanguagesOrder.length > 0 &&
            this.shortcutFirst.trim() !== '' &&
            this.shortcutSecond.trim() !== '' &&
            this.shortcutThird.trim() !== '' &&
            this.shortcutFourth.trim() !== '';
    }

    createAssistantLangObject() {
        return this.selectedLanguagesOrder.map(selectedLangName => {
            const selectedLang = this.languages.find(lang => lang.name === selectedLangName);

            return {
                name: selectedLang?.name || '',
                locale: selectedLang?.locale || '',
                voice: selectedLang?.selectedVoice || ''
            };
        });
    }

    createAssistantObject() {
        const newAssistant: Assistant = {
            name: this.assistantName,
            role: this.assistantRole,
            knowledgeScope: this.knowledgeScope,
            similarityThreshold: this.similarityThreshold,
            communicationStyle: this.communicationStyle,
            behavior: this.behavior,
            answerLength: this.answerLengthThreshold,
            selectedLanguages: this.createAssistantLangObject(),
            shortcuts: {
                first: this.shortcutFirst,
                second: this.shortcutSecond,
                third: this.shortcutThird,
                fourth: this.shortcutFourth
            }
        };

        return newAssistant;
    }

    resetLangs() {
        this.languages.forEach(language => {
            language.selected = false;
            language.selectedVoice = '';
        });
    }

    ngOnDestroy(): void {
        this.resetLangs();
    }
}
