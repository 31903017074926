import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})
export class AlertService {

    constructor() { }

    showLoading() {
         Swal.fire({
            allowOutsideClick: false,
            background: 'rgba(255, 255, 255, 0.0)',
            backdrop: 'rgba(255, 255, 255, 0.5)',
            html: `
                <div class="loader-container">
                    <div class="loader">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <p class="loading-text">Loading</p>
                </div>`,
            didOpen: () => {
                Swal.showLoading();
            }
        });
    }

    showError(title: string, text: string) {
        Swal.fire({
            allowOutsideClick: false,
            imageUrl: '../../../assets/icons/error.svg',
            imageAlt: 'Custom error image',
            title: title,
            text: text
        });
    }

    showSucess(title: string, text: string) {
        Swal.fire({
            allowOutsideClick: false,
            imageUrl: '../../../assets/icons/sucess.svg',
            imageAlt: 'Custom sucess image',
            title: title,
            text: text
        });
    }

    /*showSaveChanges(title: string, text: string) {
        Swal.fire({
            allowOutsideClick: false,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: 'Save',
            imageUrl: '../../../assets/icons/info.svg',
            imageAlt: 'Custom save hanges image',
            title: title,
            text: text
        });
    }*/

    showSaveChanges(title: string, text: string): Promise<any> {
        return Swal.fire({
            allowOutsideClick: false,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: 'Save',
            imageUrl: '../../../assets/icons/info.svg',
            imageAlt: 'Custom save changes image',
            title: title,
            text: text
        });
    }

    /*showSaveChanges(title: string, text: string) {
        Swal.fire({
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonText: 'OK',  // Tekst na OK dugmetu
            cancelButtonText: 'Cancel',
            imageUrl: '../../../assets/icons/info.svg',
            imageAlt: 'Custom save changes image',
            title: title,
            text: text
        }).then((result) => {
            if (result.isConfirmed) {
                console.log('OK button clicked');
            } else if (result.isDismissed) {
                console.log('Cancel button clicked or dismissed');
            }
        });
    }*/

    close() {
        Swal.close();
    }
}
