import { Directive } from '@angular/core';

@Directive({
    selector: '[appExample]'
})
export class ExampleDirective {

  constructor() { }

}
