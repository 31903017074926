import { Component, OnDestroy, OnInit } from '@angular/core';

import { Language } from "../../../core/models/language.model";
import { ConfigService } from "../../../core/services/config.service";
import { Assistant } from "../../../core/models/assistant.model";
import { EventService } from "../../../core/services/event.service";
import { AssistantService } from "../../../core/services/assistant.service";
import { AlertService } from "../../../core/services/alert.service";
import { DialogService } from "../../../core/services/dialog.service";

@Component({
    selector: 'app-create-assistant-modal',
    templateUrl: './create-assistant-modal.component.html',
    styleUrl: './create-assistant-modal.component.scss'
})
export class CreateAssistantModalComponent implements OnInit, OnDestroy {
    dialogTitle = 'Create new Assistant';
    createButton = 'Create';
    assistantLabels: string[] = ['Assistant name', 'Assistant role', 'Enter assistant name', 'Enter assistant role']
    knowledgeLabels: string[] = ['Knowledge Scope', 'Controlled', 'Utilizes only custom knowledge.', 'Mixed',
        'Combines both general and custom knowledge.'];
    similarityLabel: string = 'Similarity threshold for attachments (Higher percentage means fewer attachments shown)';
    communicationLabels: string[] = ['Communication style', 'Formal', 'Maintains professionalism, following social norms. ',
        'Informal', 'Keeps things casual and relaxed, using everyday language.'];
    behaviorLabels: string[] = ['Behavior', 'Friendly', 'Approaches interactions warmly, making others feel valued.', 'Curious',
        'Shows a strong interest in learning, often asking questions.', 'Creative',
        'Thinks innovatively, finding unique solutions to problems.', 'Humorous',
        'Injects humor into conversations, aiming to lighten the mood.'];
    asnwerLabel: string = 'Answer length (As the length of the answer increases, the response time also increases)';
    voiceLabels: string[] = ['Voices (First one selected is considered default)', 'Select language(s)'];
    shortcutLabels: string[] = ['Shortcuts', 'Type first shortcut', 'Type second shortcut', 'Type third shortcut', 'Type fourth shortcut']

    languages: Language[] = [];
    selectedLanguagesOrder: string[] = [];
    selectedLanguages: string = '';
    assistantName: string = '';
    assistantRole: string = '';
    shortcutFirst: string = '';
    shortcutSecond: string = '';
    shortcutThird: string = '';
    shortcutFourth: string = '';
    knowledgeScope: string = 'controlled';
    similarityThreshold: number = 0;
    answerLengthThreshold: number = 0;
    communicationStyle: string = 'formal';
    behavior: string = 'friendly';

    constructor(private config: ConfigService, private event: EventService, private assistant: AssistantService,
                private alert: AlertService, private dialog: DialogService) {}

    ngOnInit() {
        this.languages = this.config.getLanguages();
    }

    toggleVoices(language: Language) {
        this.addLanguageToOrder(language);
        this.setFemaleDefaultVoice(language);
    }

    addLanguageToOrder(language: Language) {
        if (language.selected) {
            if (!this.selectedLanguagesOrder.includes(language.name)) {
                this.selectedLanguagesOrder.push(language.name);
            }
        } else {
            this.selectedLanguagesOrder = this.selectedLanguagesOrder.filter(lang => lang !== language.name);
        }

        this.updateSelectedLanguages();
    }

    setFemaleDefaultVoice(language: Language) {
        if (language.selected) {
            language.selectedVoice = language.voices.length > 0 ? language.voices[0].name : '';
        } else {
            language.selectedVoice = '';
        }
    }

    updateSelectedLanguages() {
        this.selectedLanguages = this.selectedLanguagesOrder.join(', ');
    }

    async createAssistant() {
        this.alert.showLoading();

        const assistant = this.createAssistantObject();
        console.log(assistant)

        const response = await this.assistant.createAssistant(assistant);
        if (response) {
            await this.assistant.getAssistants('');
            this.closeDialog();
            this.openDialog();
        }
    }

    openDialog() {
        this.dialog.openSucessCreatedAssistantDialog();
    }

    closeDialog() {
        this.resetFieldValues();
        this.dialog.close();
    }

    // helpers
    get isFormValid(): boolean {
        return this.assistantName.trim() !== '' &&
            this.assistantRole.trim() !== '' &&
            this.selectedLanguagesOrder.length > 0 &&
            this.shortcutFirst.trim() !== '' &&
            this.shortcutSecond.trim() !== '' &&
            this.shortcutThird.trim() !== '' &&
            this.shortcutFourth.trim() !== '';
    }

    createAssistantLangObject() {
        return this.selectedLanguagesOrder.map(selectedLangName => {
            const selectedLang = this.languages.find(lang => lang.name === selectedLangName);

            return {
                name: selectedLang?.name || '',
                locale: selectedLang?.locale || '',
                voice: selectedLang?.selectedVoice || ''
            };
        });
    }

    createAssistantObject() {
        const newAssistant: Assistant = {
            name: this.assistantName,
            role: this.assistantRole,
            knowledgeScope: this.knowledgeScope,
            similarityThreshold: this.similarityThreshold,
            communicationStyle: this.communicationStyle,
            behavior: this.behavior,
            answerLength: this.answerLengthThreshold,
            selectedLanguages: this.createAssistantLangObject(),
            shortcuts: {
                first: this.shortcutFirst,
                second: this.shortcutSecond,
                third: this.shortcutThird,
                fourth: this.shortcutFourth
            }
        };

        return newAssistant;
    }

    resetFieldValues() {
        this.languages.forEach(language => {
            language.selected = false;
            language.selectedVoice = '';
        });

        this.event.setSimilarityThrashold(0);
        this.event.setAnswerLength(20);
    }

    resetLangs() {
        this.languages.forEach(language => {
            language.selected = false;
            language.selectedVoice = '';
        });
    }

    ngOnDestroy(): void {
        this.resetLangs();
    }
}
