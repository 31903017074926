import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { MatSidenav, MatSidenavContainer, MatSidenavModule } from "@angular/material/sidenav";
import { MatButtonModule } from "@angular/material/button";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FormsModule } from "@angular/forms";
import { RouterLink, RouterModule, Routes } from "@angular/router";

import { ExampleComponent } from "./example/example.component";
import { LandingComponent } from "./landing/landing.component";
import { ActionComponent } from "./action/action.component";
import { AssistantComponent } from "./assistant/assistant.component";
import { KnowledgeComponent } from "./knowledge/knowledge.component";
import { SharedModule } from "../../shared/shared.module";

const routes: Routes = [
    {
        path: '', component: LandingComponent
    },
    {
        path: 'example', component: ExampleComponent, children: [
            { path: '', redirectTo: 'assistants', pathMatch: 'full' }, // Default child root path
            { path: 'assistants', component: AssistantComponent },
            { path: 'knowledge', component: KnowledgeComponent },
            { path: 'action', component: ActionComponent }
        ]
    }
];

@NgModule({
    declarations: [
        LandingComponent,
        ExampleComponent,
        ActionComponent,
        AssistantComponent,
        KnowledgeComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        NgOptimizedImage,
        MatTooltipModule,
        MatButtonModule,
        SharedModule,
        RouterLink,
        MatSidenavContainer,
        MatSidenavModule,
        MatSidenav,
        RouterModule,
        RouterModule.forChild(routes),
    ]
})
export class AuthPagesModule { }
