import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-assistant-icon',
    template: `
        <svg xmlns="http://www.w3.org/2000/svg" [attr.width]="width" [attr.height]="height" viewBox="0 0 40 40" fill="none">
            <path d="M12.0834 11.6667C12.0834 7.30167 15.6351 3.75 20.0001 3.75C24.3651 3.75 27.9167 7.30167 27.9167 11.6667C27.9167 16.0317 24.3651 19.5833 20.0001 19.5833C15.6351 19.5833 12.0834 16.0317 12.0834 11.6667ZM25.0001 22.0833H15.0001C9.71675 22.0833 5.41675 26.3833 5.41675 31.6667C5.41675 34.195 7.47175 36.25 10.0001 36.25H30.0001C32.5284 36.25 34.5834 34.195 34.5834 31.6667C34.5834 26.3833 30.2834 22.0833 25.0001 22.0833Z" [attr.fill]="stroke"/>
        </svg>
    `,
    styles: [`
        :host {
            --assistant-color: #0058DD;
        }
        svg path {
            fill: var(--assistant-color);
        }
    `]
})
export class AssistantIconComponent {
    @Input() stroke: string = 'var(--assistant-color)';
    @Input() width: string = '40';
    @Input() height: string = '40';
}
