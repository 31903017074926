import { Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

import { EventService } from "../../../core/services/event.service";

@Component({
    selector: 'app-similarity-threshold-range',
    templateUrl: './similarity-threshold-range.component.html',
    styleUrl: './similarity-threshold-range.component.scss',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => SimilarityThresholdRangeComponent),
        multi: true
    }]
})
export class SimilarityThresholdRangeComponent implements OnInit, ControlValueAccessor  {
    similarityThreshold: number = 0; // Default value

    constructor(private event: EventService) {}

    ngOnInit() {
        this.event.getSimilarityThrashold().subscribe(length => {
            this.similarityThreshold = length;
            this.onChange(this.similarityThreshold);
        });
    }

    onInputChange(event: Event) {
        const inputElement = event.target as HTMLInputElement;
        this.similarityThreshold = Number(inputElement.value);
        this.onChange(this.similarityThreshold);
        this.event.setSimilarityThrashold(this.similarityThreshold);
    }

    // ControlVersion event for manipulating with value of ranges
    onChange = (value: any) => {};
    onTouched = () => {};

    writeValue(value: any): void {
        if (value !== undefined) {
            this.similarityThreshold = value;
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
}
