import { Component } from '@angular/core';

import { NavBarElementDisplayFormat } from "../../../core/models/navBarElement.model";
import { RouterService } from "../../../core/services/router.service";

@Component({
    selector: 'app-nav-bar',
    templateUrl: './nav-bar.component.html',
    styleUrl: './nav-bar.component.scss'
})
export class NavBarComponent {
    links: NavBarElementDisplayFormat[] = [
        { name: 'Assistants', href: 'assistants' },
        { name: 'Learning Hub', href: 'knowledge' },
        { name: 'Actions', href: 'action' }
    ];

    backButton: string = 'Back';
    backButtonImage: string = 'assets/icons/back.svg';

    activeIndex: number = 0;

    constructor(private router: RouterService) { }

    back() {
        this.router.goToLanding();
    }

    setActiveLink(index: number, name: string) {
        this.activeIndex = index;
    }
}
