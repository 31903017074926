import { Injectable } from '@angular/core';
import { HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, lastValueFrom, Observable } from "rxjs";

import { ConfigService } from "./config.service";
import { HttpService } from "./http.service";
import { AlertService } from "./alert.service";
import { Assistant } from "../models/assistant.model";

@Injectable({
    providedIn: 'root'
})
export class AssistantService {
    private designs = new BehaviorSubject<any[]>([]);
    private selectedAssistant = new BehaviorSubject<any>({});

    // TODO need token
    headers: HttpHeaders = new HttpHeaders({
        'Content-Type': 'application/json'
    });

    constructor(private alert: AlertService, private config: ConfigService, private http: HttpService) { }

    getAssistantDesigns(): Observable<any[]> {
        return this.designs;
    }

    getSelectedAssistant(): Observable<any> {
        return this.selectedAssistant;
    }

    setAssistantDesigns(designs: any[]) {
        this.designs.next(designs);
    }

    setSelectedAssistant(assistant: any) {
        this.selectedAssistant.next(assistant);
    }

    // endpoint methods
    // await this.assistant.getAssistant(id);
    async getAssistant(id: string) {
        const url = `${this.config.getHttpScheme()}://${this.config.getHost()}/assistant/${id}/get`;

        try {
            const response = await lastValueFrom(
                this.http
                .setHost(url)
                .setMethod("GET")
                .setHeaders(this.headers)
                .create()
            );

            this.alert.close();
            return response;
        } catch (e: any) {
            if (e.status !== 200) {
                this.alert.close();
                this.alert.showError('Error', 'An error occurred while geting the assistant. Please try again.');
                return;
            }
        }
    }

    async getAssistants(search: string) {
        const url = `${this.config.getHttpScheme()}://${this.config.getHost()}/assistant/get/all`;

        try {
            const response = await lastValueFrom(
                this.http
                .setHost(url)
                .setMethod("GET")
                .setHeaders(this.headers)
                .setContent(search)
                .create()
            );

            this.alert.close();
            return response;
        } catch (e: any) {
            if (e.status !== 200) {
                this.alert.close();
                this.alert.showError('Error', 'An error occurred while geting the assistants. Please try again.');
                return;
            }
        }
    }

    async createAssistant(assistant: any) {
        const url = `${this.config.getHttpScheme()}://${this.config.getHost()}/assistant/create`;

        try {
            const response = await lastValueFrom(
                this.http
                .setHost(url)
                .setMethod("POST")
                .setHeaders(this.headers)
                .setContent(assistant)
                .create()
            );

            this.setAssistantDesigns(response.designs);

            return response;
        } catch (e: any) {
            if (e.status !== 200) {
                this.alert.close();
                this.alert.showError('Error', 'An error occurred while creating the assistant. Please try again.');
                return;
            }
        }
    }

    async editAssistant(assistantId: string, assistant: any) {
        const url = `${this.config.getHttpScheme()}://${this.config.getHost()}/assistant/${assistantId}/update`;

        try {
            const response = await lastValueFrom(
                this.http
                .setHost(url)
                .setMethod("POST")
                .setHeaders(this.headers)
                .setContent(assistant)
                .create()
            );

            this.alert.close();
            return response;
        } catch (e: any) {
            if (e.status !== 200) {
                this.alert.close();
                this.alert.showError('Error', 'An error occurred while updating the assistant. Please try again.');
                return;
            }
        }
    }
}
