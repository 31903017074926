import { Injectable } from '@angular/core';
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class RouterService {
    constructor(private router: Router) {}

    goToLogin() {
        this.router.navigate(['/login']);
    }

    goToLanding() {
        this.router.navigate(['/landing']);
    }

    goToAssistant() {
        this.router.navigate(['landing/example/assistants']);
    }

    goToKnowledge() {
        this.router.navigate(['landing/example/knowledge']);
    }

    goToAction() {
        this.router.navigate(['landing/example/action']);
    }

    goToLostConnection() {
        this.router.navigate(['/lost-connection']);
    }

    goToNotFound() {
        this.router.navigate(['/not-found']);
    }
}
