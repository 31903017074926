<div class="loader-container">
    <div class="loader">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div>
    <p class="loading-text">{{ loading }}</p>
</div>
