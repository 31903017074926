<div class="box-landing">
    <app-header></app-header>
    <div class="landing-container">
        <div class="landing-inside-container">
            <div class="screens-container">
                <ul>
                    <li id="id-assistant" (click)="openAssistant()">
                        <div class="screen-title-container">
                            <h3>{{ screenAssistant.title }}</h3>
                            <app-assistant-icon></app-assistant-icon>
                        </div>
                        <p>{{ screenAssistant.description }}</p>
                    </li>
                    <li id="id-data-insight" (click)="openDataInsight()">
                        <div class="screen-title-container">
                            <h3>{{ screenDataInsight.title }}</h3>
                            <app-data-insight></app-data-insight>
                        </div>
                        <p>{{ screenDataInsight.description }}</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>
