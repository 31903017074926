import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatButtonModule } from "@angular/material/button";
import { RouterLink, RouterLinkActive } from "@angular/router";
import { MatDialogModule } from "@angular/material/dialog";

import { ExampleDirective } from "./directives/example.directive";
import { ExamplePipe } from "./pipes/example.pipe";
import { HeaderComponent } from "./components/header/header.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { NavBarComponent } from "./components/nav-bar/nav-bar.component";
import { KnowledgeComponent } from "./svgs/knowledge.component";
import { ActionComponent } from "./svgs/action.component";
import { DataInsightComponent } from "./svgs/data-insight.component";
import { AssistantIconComponent } from "./svgs/assistant-icon.component";
import { MatToolbarModule } from "@angular/material/toolbar";
import { SearchAreaComponent } from "./components/search-area/search-area.component";
import { CreateAssistantModalComponent } from "./components/create-assistant-modal/create-assistant-modal.component";
import { MatFormField, MatFormFieldModule } from "@angular/material/form-field";
import { MatInput, MatInputModule } from "@angular/material/input";
import { CloseComponent } from "./svgs/close.component";
import { SimilarityThresholdRangeComponent } from "./components/similarity-threshold-range/similarity-threshold-range.component";
import { AnswerThresholdRangeComponent } from "./components/answer-threshold-range/answer-threshold-range.component";
import { CreatedAssistantModalComponent } from "./components/created-assistant-modal/created-assistant-modal.component";
import { GoToAssistantComponent } from "./svgs/go-to-assistant.component";
import { CorrectComponent } from "./svgs/correct.component";
import { CopyComponent } from "./svgs/copy.component";
import { CreateActionModalComponent } from "./components/create-action-modal/create-action-modal.component";
import { CreateKnowledgeModalComponent } from "./components/create-knowledge-modal/create-knowledge-modal.component";
import { EditAssistantComponent } from "./svgs/edit-assistant.component";
import {
    AddKnowledgeToAssistantModalComponent
} from "./components/add-knowledge-to-assistant-modal/add-knowledge-to-assistant-modal.component";
import { EditAssistantModalComponent } from "./components/edit-assistant-modal/edit-assistant-modal.component";
import { ArrowComponent } from "./svgs/arrow.component";
import { AccountComponent } from "./components/account/account.component";

@NgModule({
    // declaration of components, directives, guards, pipes, svgs from SHARED directory
    declarations: [
        ExampleDirective,
        ExamplePipe,

        HeaderComponent,
        AssistantIconComponent,
        DataInsightComponent,
        LoaderComponent,
        NavBarComponent,
        KnowledgeComponent,
        ActionComponent,
        SearchAreaComponent,
        CloseComponent,
        SimilarityThresholdRangeComponent,
        AnswerThresholdRangeComponent,
        GoToAssistantComponent,
        CorrectComponent,
        CopyComponent,
        EditAssistantComponent,
        ArrowComponent,
        AccountComponent,

        // assistant modals
        CreateAssistantModalComponent,
        CreatedAssistantModalComponent,
        CreateActionModalComponent,
        CreateKnowledgeModalComponent,
        AddKnowledgeToAssistantModalComponent,
        EditAssistantModalComponent
    ],
    exports: [
        HeaderComponent,
        AssistantIconComponent,
        DataInsightComponent,
        LoaderComponent,
        NavBarComponent,
        KnowledgeComponent,
        ActionComponent,
        SearchAreaComponent,
        SimilarityThresholdRangeComponent,
        AnswerThresholdRangeComponent,
        GoToAssistantComponent,
        CorrectComponent,
        CopyComponent,
        EditAssistantComponent,
        ArrowComponent,
        AccountComponent,

        //modals
        CreateAssistantModalComponent,
        CreatedAssistantModalComponent,
        CreateActionModalComponent,
        CreateKnowledgeModalComponent,
        AddKnowledgeToAssistantModalComponent,
        EditAssistantModalComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        MatDialogModule,
        NgOptimizedImage,
        MatTooltipModule,
        MatButtonModule,
        RouterLink,
        RouterLinkActive,
        MatToolbarModule,
        ReactiveFormsModule,
        MatFormField,
        MatInput,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        ReactiveFormsModule
    ]
})
export class SharedModule {}
