import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import {
    CreateAssistantModalComponent
} from "../../shared/components/create-assistant-modal/create-assistant-modal.component";
import {
    CreateKnowledgeModalComponent
} from "../../shared/components/create-knowledge-modal/create-knowledge-modal.component";
import { CreateActionModalComponent } from "../../shared/components/create-action-modal/create-action-modal.component";
import {
    CreatedAssistantModalComponent
} from "../../shared/components/created-assistant-modal/created-assistant-modal.component";
import {
    EditAssistantModalComponent
} from "../../shared/components/edit-assistant-modal/edit-assistant-modal.component";
import {
    AddKnowledgeToAssistantModalComponent
} from "../../shared/components/add-knowledge-to-assistant-modal/add-knowledge-to-assistant-modal.component";

@Injectable({
    providedIn: 'root'
})
export class DialogService {
    private dialogRef: MatDialogRef<any> | null = null;

    constructor(private dialog: MatDialog) {}

    open(component: any, width: string): void {
        if (!this.dialogRef) {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;
            dialogConfig.width = width;
            dialogConfig.height = 'auto';

            this.dialogRef = this.dialog.open(component, dialogConfig);
        }
    }

    close(): void {
        if (this.dialogRef) {
            this.dialogRef.close();
            this.dialogRef = null;
        }
    }

    openCreateAssistantDialog(): void {
        this.open(CreateAssistantModalComponent, '750px');
    }

    openEditAssistantDialog(): void {
        this.open(EditAssistantModalComponent, '750px');
    }

    openAddKnowledgeToAssistantDialog(): void {
        this.open(AddKnowledgeToAssistantModalComponent, '750px');
    }

    openSucessCreatedAssistantDialog(): void {
        this.open(CreatedAssistantModalComponent, '650px');
    }

    openCreateKnowledgeDialog(): void {
        this.open(CreateKnowledgeModalComponent, '80%');
    }

    openCreateActionDialog(): void {
        this.open(CreateActionModalComponent, '80%');
    }
}
