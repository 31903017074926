import { Component, OnInit } from '@angular/core';
import { Title } from "@angular/platform-browser";

import { NavBarOptionModel } from "../../../core/models/navBarOption.model";
import { EventService } from "../../../core/services/event.service";
import { DialogService } from "../../../core/services/dialog.service";

@Component({
    selector: 'app-action',
    templateUrl: './action.component.html',
    styleUrl: './action.component.scss'
})
export class ActionComponent implements OnInit {
    plusImage: string = 'assets/icons/plus.svg';

    descriptionSection: string[] = [
        `Welcome to Actions dashboard!`,
        `Set up and manage advanced actions that digital assistant can perform in response to user interactions.`];

    constructor (private title: Title, private dialog: DialogService, private event: EventService) {
        this.title.setTitle('Actions');
    }

    ngOnInit(): void {
        this.event.setCurrentTab('Actions');

        const option: NavBarOptionModel = { title: 'Actions', search: 'Type here to search action...', create: 'Create new action' };
        this.event.setContentFromNavbarOption(option)
    }

    add() {
        this.dialog.openCreateActionDialog();
    }
}
