import { EventEmitter, Injectable } from '@angular/core';

import { NavBarOptionModel } from "../models/navBarOption.model";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class EventService {
    currentNavbarTab = new BehaviorSubject<string>('');
    contentFromNavbarOption = new BehaviorSubject<NavBarOptionModel | null>(null);
    answerLengthValue = new BehaviorSubject<number>(20); // Default value
    similarityThrasholdValue = new BehaviorSubject<number>(0); // Default value

    constructor() { }

    getAnswerLength(): Observable<number> {
        return this.answerLengthValue;
    }

    getSimilarityThrashold(): Observable<number> {
        return this.similarityThrasholdValue;
    }

    getContentFromNavbarOption(): Observable<NavBarOptionModel | null> {
        return this.contentFromNavbarOption;
    }

    getCurrentTab(): Observable<string> {
        return this.currentNavbarTab;
    }

    setAnswerLength(length: number) {
        this.answerLengthValue.next(length);
    }

    setSimilarityThrashold(similarity: number) {
        this.similarityThrasholdValue.next(similarity);
    }

    setContentFromNavbarOption(navBaroption: NavBarOptionModel | null) {
        this.contentFromNavbarOption.next(navBaroption);
    }

    setCurrentTab(tab: string) {
        this.currentNavbarTab.next(tab);
    }
}
