<div class="assistant-container">
    <div *ngIf="isAssistantEmpty()" class="initial-container">
        <div class="button-container">
            <button id="id-add-assistant-button" type="button" class="add-button" (click)="add()">
                <img [src]="plusImage" alt="Plus icon">
            </button>
        </div>
        <div class="description-container">
            <p>{{ descriptionSection[0] }}</p>
            <p>{{ descriptionSection[1] }}</p>
        </div>
    </div>
    <div *ngIf="!isAssistantEmpty()" class="assistants-container">
        <div class="assistant-list-container">
            <div id="id-toggle-assistants" class="assistants-header-container">
                <button id="id-arrow-button" class="arrow-button" (click)="toggleDropdown()">
                    <app-arrow></app-arrow>
                </button>
                <p (click)="toggleDropdown()" class="select-assistant"> {{ getSelectedAssistantLabel() }}</p>
                <button *ngIf="isSelectAssistant()" id="id-edit-assistant" class="edit-assistant" (click)="editAssistant()">
                    <app-edit-assistant></app-edit-assistant>
                </button>
            </div>

            <div *ngIf="dropdownOpen" class="assistants-list">
                <div id="id-assistant-item" class="assistant-item" *ngFor="let assistant of assistants; let i = index"
                     [class.selected]="selectedAssistantIndex === i" (click)="selectAssistant(i, assistant)"> {{ assistant.name }}
                    <span class="icon-container">
                        <img *ngIf="selectedAssistantIndex === i" [src]="checkImage" alt="Check icon" class="checkmark">
                        <img *ngIf="selectedAssistantIndex !== i" [src]="hoverImage" alt="Hover icon" class="hover-checkmark">
                    </span>
                </div>
            </div>
        </div>

        <div class="tabs-container">
            <div id="id-tab-knowledge" class="tab" [class.active]="activeTab === 'knowledge'" (click)="selectTab('knowledge')">
                {{ assistantKnowledgeLabel }}
            </div>
            <div id="id-tab-analytics" class="tab" [class.active]="activeTab === 'analytics'" (click)="selectTab('analytics')">
                {{ assistantAnalyticsLabel }}
            </div>
        </div>

        <div *ngIf="activeTab === 'knowledge'" class="knowledge-container">
            <div *ngIf="!isSelectAssistant()" class="content-center">
                <img [src]="cursorImage" alt="Select Icon">
                <p>{{ selectSection }}</p>
            </div>
            <div *ngIf="isSelectAssistant()" class="content-center">
                <button id="id-add-knowledge-button" type="button" class="add-button" (click)="addKnowledge()">
                    <img [src]="plusImage" alt="Plus knowledge icon">
                </button>
                <p>{{ knowledgeSection }}</p>
            </div>
        </div>

        <div *ngIf="activeTab === 'analytics'" class="analytics-container">
        </div>
    </div>
</div>
