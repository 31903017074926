import { Component, OnInit } from '@angular/core';
import { Title } from "@angular/platform-browser";

import { EventService } from "../../../core/services/event.service";
import { NavBarOptionModel } from "../../../core/models/navBarOption.model";
import { DialogService } from "../../../core/services/dialog.service";

@Component({
    selector: 'app-knowledge',
    templateUrl: './knowledge.component.html',
    styleUrl: './knowledge.component.scss'
})
export class KnowledgeComponent implements OnInit {
    plusImage: string = 'assets/icons/plus.svg';

    descriptionSection: string[] = [
        `Welcome to the Learning Hub!`,
        `Create knowledge through multiple channels, which can then be assigned to the desired Assistant.`];

    constructor (private title: Title, private event: EventService, private dialog: DialogService) {
        this.title.setTitle('Knowledge');
    }

    ngOnInit(): void {
        this.event.setCurrentTab('Learning Hub');

        const option: NavBarOptionModel = { title: 'Learning Hub', search: 'Type here to search knowledge...', create: 'Create new knowledge' };
        this.event.setContentFromNavbarOption(option)
    }

    add() {
        this.dialog.openCreateKnowledgeDialog()
    }
}
