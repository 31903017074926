import { Injectable } from '@angular/core';
import { HttpHeaders } from "@angular/common/http";
import { lastValueFrom } from "rxjs";

import { ConfigService } from "./config.service";
import { RouterService } from "./router.service";
import { HttpService } from "./http.service";
import { AlertService } from "./alert.service";

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    headers: HttpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization' : 'Bearer ' + this.getToken()
    });

    constructor(private http: HttpService, private config: ConfigService, private router: RouterService, private alert: AlertService) { }

    // getters
    getToken(){
        return localStorage.getItem("token");
    }

    // setters
    setToken(token: string){
        localStorage.setItem("token", token);
    }

    async login (username: string, password: string) {
        const url = `${this.config.getHttpScheme()}://${this.config.getHost()}/auth/login`;

        const formData = new FormData();
        formData.append("username", username);
        formData.append("password", password);

        try {
            const response = await lastValueFrom(
                this.http
                .setHost(url)
                .setMethod("POST")
                .setHeaders(this.headers)
                .setContent(formData)
                .create()
            );

            this.setToken(response.token);
            this.alert.close();
            this.router.goToLanding();
            return response;
        } catch (e: any) {
            this.alert.close();

            if (e.status === 401) {
                this.alert.showError('Unauthorized!', 'Incorrect username or password. Please try again.');
                return;
            }

            if (e.status !== 200) {
                this.alert.showError('Login failed!', 'Incorrect username or password. Please try again.');
                return;
            }
        }
    }

    async forgotPassword (email: string) {
        const url = `${this.config.getHttpScheme()}://${this.config.getHost()}/auth/forgot-password`;

        const formData = new FormData();
        formData.append("email", email);

        try {
            const response = await lastValueFrom(
                this.http
                .setHost(url)
                .setMethod("POST")
                .setHeaders(this.headers)
                .setContent(formData)
                .create()
            );

            console.log(response.body);
            this.alert.close();

            this.alert.showSucess('Successfully sent!', 'Check your email for further instructions.');

            return response;
        } catch (e: any) {
            this.alert.close();

            if (e.status !== 200) {
                this.alert.showError('Forgot password failed!', 'Check if your email address is correct. Please try again.');
                return;
            }
        }
    }

    isAuthenticated(): boolean {
        return !!localStorage.getItem('token');
    }

    logout(){
        localStorage.removeItem("token");
        this.router.goToLogin();
    }
}
