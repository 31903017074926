<mat-dialog-content>
    <div class="dialog-container">
        <div class="dialog-header">
            <h2>{{ dialogTitle }}</h2>
            <button id="id-close-action-dialog" type="button" class="close-button" (click)="closeDialog()">
                <app-close></app-close>
            </button>
        </div>
        <div>

        </div>
    </div>
</mat-dialog-content>
