import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-data-insight',
    template: `
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="insight 1">
                <g id="Group">
                    <path id="Vector" d="M9.02151 15.8966H5.82666C4.1844 15.8966 2.84924 17.2319 2.84924 18.8741V32.3827C2.84924 34.0249 4.18448 35.3609 5.82666 35.3609H9.02151C10.6637 35.3609 11.9997 34.025 11.9997 32.3827V18.8741C11.9997 17.2319 10.6638 15.8966 9.02151 15.8966Z" [attr.fill]="stroke"/>
                    <path id="Vector_2" d="M21.5975 10.4977H18.4026C16.7605 10.4977 15.4244 11.8337 15.4244 13.4759V32.3828C15.4244 34.025 16.7604 35.361 18.4026 35.361H21.5975C23.2397 35.361 24.5757 34.0251 24.5757 32.3828V13.4759C24.5757 11.8337 23.2397 10.4977 21.5975 10.4977Z" [attr.fill]="stroke"/>
                    <path id="Vector_3" d="M34.1734 4.63906H30.9786C29.3364 4.63906 28.0004 5.975 28.0004 7.61726V32.3828C28.0004 34.025 29.3363 35.361 30.9786 35.361H34.1734C35.8157 35.361 37.1508 34.0251 37.1508 32.3828V7.61726C37.1508 5.975 35.8156 4.63906 34.1734 4.63906Z" [attr.fill]="stroke"/>
                </g>
            </g>
        </svg>
    `,
    styles: [`
        :host {
            --data-insight-color: #0058DD;
        }
        svg path {
            fill: var(--data-insight-color);
        }
    `]
})
export class DataInsightComponent {
    @Input() stroke: string = 'var(--data-insight-color)';
}
