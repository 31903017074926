import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-arrow',
    template: `
        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 1L7 7L1 1" [attr.stroke]="stroke" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    `,
    styles: [`
        :host {
            --arrow-color: #8194A6;
        }
        svg g path {
            fill: var(--arrow-color);
        }
    `]
})
export class ArrowComponent {
    @Input() stroke: string = 'var(--arrow-color)';
}
