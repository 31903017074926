import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-knowledge',
    template: `
        <svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Icon">
                <path id="Vector" fill-rule="evenodd" clip-rule="evenodd" d="M1.67442 2.23254H0.837209C0.37507 2.23254 0 2.60761 0 3.06975V17.5814C0 18.0435 0.37507 18.4186 0.837209 18.4186H9.52075C8.112 17.674 6.13619 17.3023 4.57005 17.3023H3.62791C2.54958 17.3023 1.67442 16.4271 1.67442 15.3488V2.23254ZM22.3256 2.23254V15.3488C22.3256 16.4271 21.4504 17.3023 20.3721 17.3023H19.43C17.8638 17.3023 15.888 17.674 14.4793 18.4186H23.1628C23.6249 18.4186 24 18.0435 24 17.5814V3.06975C24 2.60761 23.6249 2.23254 23.1628 2.23254H22.3256Z" [attr.fill]="stroke"/>
                <path id="Vector_2" fill-rule="evenodd" clip-rule="evenodd" d="M11.1626 18.067V1.62754L11.0822 1.58065C9.27946 0.551442 6.64615 0 4.56988 0H3.62774C3.1656 0 2.79053 0.37507 2.79053 0.837209V15.3488C2.79053 15.811 3.1656 16.186 3.62774 16.186H4.56988C6.37936 16.186 8.68002 16.6482 10.2517 17.5468L11.1626 18.067Z" [attr.fill]="stroke"/>
                <path id="Vector_3" fill-rule="evenodd" clip-rule="evenodd" d="M12.8374 1.62754V18.067L13.7483 17.5468C15.32 16.6482 17.6207 16.186 19.4301 16.186H20.3723C20.8344 16.186 21.2095 15.811 21.2095 15.3488V0.837209C21.2095 0.37507 20.8344 0 20.3723 0H19.4301C17.3539 0 14.7206 0.551442 12.9178 1.58065L12.8374 1.62754Z" [attr.fill]="stroke"/>
            </g>
        </svg>
    `,
    styles: [`
        :host {
            --knowledge-color: #31405A;
        }
        svg g path {
            fill: var(--knowledge-color);
        }
    `]
})
export class KnowledgeComponent {
    @Input() stroke: string = 'var(--knowledge-color)';
}
