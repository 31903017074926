import { Component, OnDestroy, OnInit } from '@angular/core';
import { Clipboard } from "@angular/cdk/clipboard";
import { Subscription } from "rxjs";

import { AssistantService } from "../../../core/services/assistant.service";
import { DialogService } from "../../../core/services/dialog.service";

@Component({
    selector: 'app-created-assistant-modal',
    templateUrl: './created-assistant-modal.component.html',
    styleUrl: './created-assistant-modal.component.scss'
})
export class CreatedAssistantModalComponent implements OnInit, OnDestroy {
    sucessImage: string = 'assets/icons/sucess.svg';
    plusImage: string = 'assets/icons/plus.svg';
    dialogTitle = 'Assistant sucessfully created!';
    dialogDescription = 'Add knowledge or go to Agent screen.';
    knowledgeButton = 'Add Knowledge to Assistant';
    assistantButton = 'Go to Assistant';
    goToLinkButton = 'Go to link';

    designs = [
        { id: 'design-1', label: 'Design 1 (Avatar and chat)', link: '' },
        { id: 'design-2', label: 'Design 2 (Chat)', link: '' },
        { id: 'design-3', label: 'Design 3 (Avatar)', link: '' },
        { id: 'design-4', label: 'Design 4 (Full page)', link: '' }
    ];

    isLinkCopied = [false, false, false, false];
    isGoToAssistant: boolean = false;

    designsSubscription!: Subscription;

    constructor(private dialog: DialogService, private clipboard: Clipboard, private assistant: AssistantService) { }

    ngOnInit(): void {
        this.designsSubscription = this.assistant.getAssistantDesigns().subscribe(designs => {

            this.designs = this.designs.map(design => {
                const serverDesign = designs.find(d => d.id === design.id);
                return { ...design,  link: serverDesign ? serverDesign.link : '' };
            });

            console.log(this.designs);
        });
    }

    copyTextMessage(content: string, index: number) {
        this.clipboard.copy(content);
        this.isLinkCopied[index] = true;

        setTimeout(() => { this.isLinkCopied[index] = false; }, 2000);
    }

    goToLink(link: string) {
        window.open(link, '_blank');
    }

    addKnowledge() {
        this.closeDialog();
        this.dialog.openAddKnowledgeToAssistantDialog()
    }

    goToAssistant() {
        this.isGoToAssistant = true;
    }

    closeDialog() {
        this.dialog.close();
    }

    ngOnDestroy(): void {
        if (this.designsSubscription) { this.designsSubscription.unsubscribe(); }
    }
}
