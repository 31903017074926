import { Injectable } from '@angular/core';

import { environment } from "../../../environments/environment";
import { Language } from "../models/language.model";
import { Voice } from "../models/voice.model";

@Injectable({
    providedIn: 'root'
})
export class ConfigService {

    voices: Voice[] = [
        { id: 'voice-1', name: 'Female', gender: 'female' },
        { id: 'voice-2', name: 'Male', gender: 'male' }
    ];

    languages: Language[] = [
        { id: '1', name: 'English', locale: 'en-US', order: '', selected: false, selectedVoice: '', voices: this.voices },
        { id: '2', name: 'Arabic', locale: 'ar-SA', order: '', selected: false, selectedVoice: '', voices: this.voices },
        { id: '3', name: 'German', locale: 'de-DE', order: '', selected: false, selectedVoice: '', voices: this.voices },
        { id: '4', name: 'Turkish', locale: 'tr-TR', order: '', selected: false, selectedVoice: '', voices: this.voices },
        { id: '5', name: 'Spanish', locale: 'es-ES', order: '', selected: false, selectedVoice: '', voices: this.voices },
        { id: '6', name: 'French', locale: 'fr-FR', order: '', selected: false, selectedVoice: '', voices: this.voices },
        { id: '7', name: 'Russian', locale: 'ru-RU', order: '', selected: false, selectedVoice: '', voices: this.voices },
        { id: '8', name: 'Chinese', locale: 'zh-CN', order: '', selected: false, selectedVoice: '', voices: this.voices }
    ];

    constructor() { }

    // getters environment
    getHost () {
        return environment.apiUrl;
    }

    getOrchestrationHost () {
        return environment.orchestratorUrl;
    }

    getWssScheme () {
        return environment.wssScheme;
    }

    getHttpScheme () {
        return environment.httpScheme;
    }

    getUserName() {
        return 'User';
    }

    // getters config
    getLanguages () {
        return this.languages;
    }
}
