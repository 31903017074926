import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-action',
    template: `
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Group">
                <path id="Vector" d="M12.8031 11.2469V8.4256L9.17874 12.05H10.4937C10.882 12.05 11.1969 12.3648 11.1969 12.7531V15.5744L14.8212 11.95H13.5062C13.1179 11.95 12.8031 11.6352 12.8031 11.2469Z" [attr.fill]="stroke"/>
                <path id="Vector_2" d="M23.384 9.79603L21.4298 9.55191C21.2034 8.67717 20.8578 7.84397 20.398 7.06467L21.6083 5.50856C21.8261 5.22867 21.8012 4.83047 21.5505 4.57973L19.4203 2.44955C19.1696 2.19881 18.7713 2.17402 18.4915 2.3917L16.9353 3.60206C16.1557 3.14222 15.3225 2.79652 14.4481 2.5702L14.204 0.615984C14.16 0.264047 13.8609 0 13.5062 0H10.4937C10.1391 0 9.84 0.264047 9.79603 0.615984L9.55191 2.57016C8.67717 2.79656 7.84397 3.14222 7.06467 3.60197L5.50856 2.39166C5.22867 2.17397 4.83042 2.19872 4.57973 2.4495L2.4495 4.57973C2.19877 4.83047 2.17397 5.22867 2.39166 5.50856L3.60202 7.06472C3.14217 7.8443 2.79647 8.6775 2.57016 9.55191L0.615937 9.79603C0.264047 9.84 0 10.1391 0 10.4938V13.5063C0 13.8609 0.264047 14.16 0.615984 14.204L2.57016 14.4481C2.79656 15.3228 3.14222 16.156 3.60197 16.9353L2.39166 18.4914C2.17392 18.7713 2.19877 19.1695 2.4495 19.4203L4.57969 21.5505C4.83042 21.8013 5.22867 21.826 5.50852 21.6083L7.06467 20.3979C7.84425 20.8578 8.67745 21.2035 9.55186 21.4298L9.79598 23.384C9.83995 23.7359 10.1391 24 10.4937 24H13.5062C13.8608 24 14.1599 23.736 14.2039 23.384L14.448 21.4298C15.3227 21.2034 16.1559 20.8578 16.9352 20.398L18.4913 21.6083C18.7712 21.826 19.1695 21.8013 19.4202 21.5505L21.5504 19.4203C21.8011 19.1696 21.8259 18.7714 21.6082 18.4915L20.3978 16.9353C20.8577 16.1558 21.2034 15.3225 21.4297 14.4481L23.3839 14.204C23.7358 14.16 23.9999 13.8609 23.9999 13.5063V10.4938C24 10.1391 23.736 9.84 23.384 9.79603ZM17.0159 11.7441L10.9909 17.769C10.7899 17.9701 10.4875 18.0303 10.2247 17.9215C9.96192 17.8126 9.79059 17.5562 9.79059 17.2719V13.4563H7.48125C7.19686 13.4563 6.94045 13.2849 6.83166 13.0222C6.72286 12.7595 6.783 12.457 6.98409 12.2559L13.0091 6.23091C13.2101 6.02981 13.5125 5.96962 13.7753 6.07847C14.0381 6.18731 14.2094 6.44372 14.2094 6.72806V10.5437H16.5187C16.8031 10.5437 17.0595 10.715 17.1683 10.9777C17.2772 11.2405 17.217 11.543 17.0159 11.7441Z" [attr.fill]="stroke"/>
            </g>
        </svg>
    `,
    styles: [`
        :host {
            --action-color: #31405A;
        }
        svg g path {
            fill: var(--action-color);
        }
    `]
})
export class ActionComponent {
    @Input() stroke: string = 'var(--action-color)';
}
