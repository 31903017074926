<div class="wrapper">
    <div class="box-header"><app-header></app-header></div>
    <div class="box-navbar"><app-nav-bar></app-nav-bar></div>
    <div class="box-component">
        <div class="box-search">
            <app-search-area></app-search-area>
        </div>
        <div class="box-router">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
