import { Component } from '@angular/core';

@Component({
    selector: 'app-add-knowledge-to-assistant-modal',
    templateUrl: './add-knowledge-to-assistant-modal.component.html',
    styleUrl: './add-knowledge-to-assistant-modal.component.scss'
})
export class AddKnowledgeToAssistantModalComponent {

}
