import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from "rxjs";
import { EventService } from "../../../core/services/event.service";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit, OnDestroy {
    companyName: string = "ExaControl";

    titleSection: string = '';

    navBarOptionSubscription!: Subscription;

    constructor(private event: EventService) { }

    ngOnInit(): void {
        this.navBarOptionSubscription = this.event.getContentFromNavbarOption().subscribe(navBarOption => {
            if (navBarOption) { this.titleSection = navBarOption.title; }
        });
    }

    ngOnDestroy() {
        if (this.navBarOptionSubscription) { this.navBarOptionSubscription.unsubscribe(); }
    }
}
