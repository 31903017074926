<div class="header-wrapper">
    <mat-toolbar>
        <mat-toolbar-row>
            <div id="id-new-chat" type="button" class="new-chat-button">
                <p class="company-name">{{ companyName }}</p>
            </div>
            <div class="section-title">
                <p>{{ titleSection }}</p>
            </div>
            <div class="inline-header">
                <app-account></app-account>
            </div>
        </mat-toolbar-row>
    </mat-toolbar>
</div>
