<div class="knowledge-container">
    <div class="initial-container">
        <div class="button-container">
            <button id="id-add-knowledge-button" type="button" class="add-button" (click)="add()">
                <img [src]="plusImage" alt="Plus icon">
            </button>
        </div>
        <div class="description-container">
            <p>{{ descriptionSection[0] }}</p>
            <p>{{ descriptionSection[1] }}</p>
        </div>
    </div>
</div>
